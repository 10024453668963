<template>
    <b-modal
        ref="monitorModal"
        no-close-on-backdrop
        hide-header
        centered
        ok-only
        ok-variant="secondary"
        ok-title="닫기"
    >
        <weather-card
            :loca="location"
        ></weather-card>
        <div v-for="farm in farmList" :key="farm._id">
            <hr>
            <sensor-state-card
                :farm="farm"
                :ref="'sensorStateCard-' + farm._id"
                @update-sensor="getSensorByFarm"
              />
        </div>
    </b-modal>
</template>
<script>
import { BModal } from 'bootstrap-vue'
import store from '@/store'
import WeatherCard from '@/views/users/monitoring/components/WeatherCard.vue'
import SensorStateCard from '@/views/users/monitoring/components/SensorStateCard.vue'
export default {
    components: {
        BModal,
        WeatherCard,
        SensorStateCard
    },
    name: 'Modal',
    created() {
        
    },
    mounted() {
        
    },
    props: {
        user: {
            type: Object,
            default: () => {}
        }
    },
    data() {
        return {
            farmList: null,
            location: null
        }
    },
    methods: {
        open(location) {
            this.location = location
            this.$refs.monitorModal.show()
        },
        async setFarmList(list) {
            this.farmList = list
            for(let farm of list) this.getSensorByFarm(farm)
        },
        async getSensorByFarm (farm) {
            if(farm === undefined) farm = this.farmList[0]
            let farmId = farm['_id']
            const { data } = await store.dispatch('sensor/fetchSensors', { userId: this.user.id, farmId })
            let sensorList = data
            if (sensorList.length === 0) this.farmList.splice(this.farmList.findIndex(e => e._id === farmId), 1)
            else {
                for(let idx in sensorList) {
                    let val = await store.dispatch('sensor/liveFetchSensor', { id: sensorList[idx]._id })
                    sensorList[idx].val = (val.data === '') ?'-' : val.data
                    if(val.data === '') continue
                }
                this.$refs[`sensorStateCard-${farmId}`][0].updateUI(sensorList)
                
            } 
            return
        },
    }
}
</script>

<style scoped>

</style>