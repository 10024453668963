export default [
  {
    header: 'User',
  },
/*   {
    title: '공지사항',
    route: 'board-list',
    icon: 'ClipboardIcon',
    action: 'read',
    resource: 'User',
  }, */
  {
    title: '원격제어',
    route: 'remote-control',
    icon: 'ToggleRightIcon',
    action: 'read',
    resource: 'User',
  },
  /* {
    title: '메뉴얼',
    route: 'manual',
    icon: 'UserIcon',
    action: 'read',
    resource: 'User',
  }, */
  /* {
    title: '수집로그',
    route: 'dashboard',
    icon: 'SearchIcon',
    action: 'read',
    resource: 'User',
  }, */
  {
    title: '자동설정',
    route: 'remote-setting',
    icon: 'SettingsIcon',
    action: 'read',
    resource: 'User',
  },
  {
    title: 'CCTV',
    route: 'watch-cctv',
    icon: 'VideoIcon',
    action: 'read',
    resource: 'User',
  },
  {
    title: '사료급이 기록',
    route: 'feeder-log',
    icon: 'FileIcon',
    action: 'read',
    resource: 'User',
  },
]
