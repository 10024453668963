<template>
  <b-nav-item-dropdown
    right
    toggle-class="d-flex align-items-center dropdown-user-link"
    class="dropdown-user"

  >
    <template #button-content>
      <div class="d-sm-flex d-none user-nav">
        <p class="user-name font-weight-bolder mb-0">
          {{ userData.name }}
        </p>
        <span class="user-status">{{ userData.role.toUpperCase() }}</span>
      </div>
      <b-avatar
        size="40"
        :src="userData.avatar"
        variant="light-secondary"
        badge
        class="badge-minimal"
        badge-variant="success"

      >
        <feather-icon
          v-if="!userData.name"
          icon="UserIcon"
          size="22"
        />
      </b-avatar>
    </template>

    <b-dropdown-item
      :to="{ name: 'account-setting'}"
      link-class="d-flex align-items-center"
    >
      <feather-icon
        size="16"
        icon="UserIcon"
        class="mr-50"
      />
      <span>사용자 정보</span>
    </b-dropdown-item>

    <!-- <b-dropdown-item
      v-if="userData.role === 'customer'"
      :to="{ name: 'contacts'}"
      link-class="d-flex align-items-center"
    >
      <feather-icon
        size="16"
        icon="MessageSquareIcon"
        class="mr-50"
      />
      <span>문의처</span>
    </b-dropdown-item> -->

    <b-dropdown-divider />

    <b-dropdown-item
      link-class="d-flex align-items-center"
      @click="doLogout"
    >
      <feather-icon
        size="16"
        icon="LogOutIcon"
        class="mr-50"
      />
      <span>로그아웃</span>
    </b-dropdown-item></b-nav-item-dropdown>
</template>

<script>
import {
  BNavItemDropdown, BDropdownItem, BDropdownDivider, BAvatar,
} from 'bootstrap-vue'
// import { initialAbility } from '@/libs/acl/config'
// import useJwt from '@/auth/jwt/useJwt'
import { logout } from '@/auth/utils'
import { avatarText } from '@core/utils/filter'

// import fakeUser from '@/data/user.json'

export default {
  components: {
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
      // userData: fakeUser,
      avatarText,
      roles: ''
    }
  },
/*   computed: {
    getRole: () => {
      return this.userData.role
    }
  }, */
  methods: {
    doLogout() {
      logout()
    },
  },
}
</script>
