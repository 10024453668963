<template>
  <p class="clearfix mb-0" style="text-align:center">
    <!-- <span style="color:	#808080">Updated 2023-07-06</span><br> -->
    <span class="float-md-right d-block d-md-inline-block mt-27">
      COPYRIGHT  © {{ new Date().getFullYear() }}
      <b-link
        class="ml-25"
        href="http://www.ewhaglobal.com/"
        target="_blank"
      >Ewha Global</b-link>
      <span class="d-sm-inline-block">, All rights Reserved</span>
    </span>
  </p>
</template>

<script>
import { BLink } from 'bootstrap-vue'

export default {
  components: {
    BLink,
  },
}
</script>
