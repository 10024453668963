<template>
  <div class="navbar-container d-flex content align-items-center">

    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        
        <b-link
          class="nav-link"
          @click="toggleVerticalMenuActive"
        >
          <feather-icon
            icon="MenuIcon"
            size="21"
          />
        </b-link>
      </li>
    </ul>


    <b-navbar-nav class="nav align-items-center ml-auto">
      <li >
        <p text-align="center" class="font-weight-bolder mb-0 mr-1"
          @click="getMonitor(userData)"
          :style="`margin: auto; border : 1px outset; border-radius: 3px; color: ${(serverConnect)? '#84BF7F': 'red'}`">
          <feather-icon
            :icon="(serverConnect)?'MonitorIcon': 'WifiOffIcon'"
            size="21"
          />
          <!-- <b-tooltip target="srConnectDate" triggers="hover">데이터 갱신 시간: {{connectDate}}</b-tooltip> -->
          </p>
      </li>
      <notification-dropdown />
      <user-dropdown
        class="top"
      />
    </b-navbar-nav>

    <div v-if="serverConnect">
      <monitor-modal
       ref="monitorModal"
       :user="userData"
      ></monitor-modal>
    </div>
  </div>
</template>

<script>
import {
  BLink, BNavbarNav, BModal
} from 'bootstrap-vue'
import NotificationDropdown from './components/NotificationDropdown.vue'
import UserDropdown from './components/UserDropdown.vue'
import MonitorModal from './components/MonitorModal.vue'
import { getUserData } from '@/auth/utils'
import store from '@/store'

export default {
  components: {
    BLink,
    BModal,
    // Navbar Components
    BNavbarNav,
    NotificationDropdown,
    UserDropdown,
    MonitorModal
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      userData: null,
      farmList: null,
      isUser: false,
      serverConnect: true,
      timer: '',
      connectDate: '-',
      location: null,
      error: null,
      timeoutId: null
    }
  },
  created() {
    this.getUserInfo()
    this.getFarmListAndSetInitFarm()  
    
  },
  mounted() {

  },
  destroyed() {
    clearTimeout(this.timeoutId)
  },

  methods: {
    async getFarmListAndSetInitFarm() {
      const res = await store.dispatch('farm/fetchFarms', { userId: this.userData.id })
      this.farmList = res.data

    },
    async getUserInfo() {
      this.userData = getUserData()
      this.isUser = this.userData.role === 'customer'
      if(this.isUser) this.getConnect(this.userData)
      return
    },
    async getConnect(userData) {
      this.serverConnect = false
      await store.dispatch('users/updateConnectState', false)
      try {
        let res = await store.dispatch('users/getUserConnect', { id: userData.id })
        if(res.status === 200 && res.data === 'SUCCESS') {
          if(this.isUser) this.getLocation()
          this.serverConnect = true
          clearTimeout(this.timeoutId)
          return await store.dispatch('users/updateConnectState', true)
        }
      } catch (error) {
        
      }
      this.timeoutId = setTimeout(async () => {
        await this.getConnect(userData)
      }, 1000 * 10);

    },
    async getMonitor() {
      if(!this.serverConnect) return alert('서버에 연결할 수 없습니다.')
      this.$refs.monitorModal.open(this.location)
      this.$refs.monitorModal.setFarmList(this.farmList)
      return
      //alert(JSON.stringify(this.location))
    },
    async getLocation() {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          position => {
            this.location = {
              latitude: position.coords.latitude,
              longitude: position.coords.longitude
            }
            this.error = null
          },
          error => {
            switch(error.code) {
              case error.PERMISSION_DENIED:
                this.error = "User denied the request for Geolocation."
                break
              case error.POSITION_UNAVAILABLE:
                this.error = "Location information is unavailable."
                break
              case error.TIMEOUT:
                this.error = "The request to get user location timed out."
                break
              case error.UNKNOWN_ERROR:
                this.error = "An unknown error occurred."
                break
            }
          }
        )
      } else this.error = "Geolocation is not supported by this browser."
    },
    
  },
}
</script>

<style scoped>
.top {
  z-index: 9999;
}
</style>
