export default [
  {
    header: 'Admin',
  },
  /* {
    title: '공지목록',
    route: 'board-list',
    icon: 'InfoIcon',
    action: 'manage',
    resource: 'Admin',
  }, */
  {
    title: '회원관리',
    route: 'user-list',
    icon: 'UsersIcon',
    action: 'manage',
    resource: 'Admin',
  },
  {
    title: '메시지 전송',
    route: 'push-write',
    icon: 'AlignLeftIcon',
    action: 'manage',
    resource: 'Admin',
  },
  {
    title: '수동정보연계',
    route: 'make-data-sample',
    icon: 'FilePlusIcon',
    action: 'manage',
    resource: 'Admin',
  },
  
  /* {
    title: '시시티비',
    route: 'cctv-admin',
    icon: 'MonitorIcon',
    action: 'manage',
    resource: 'Admin',
  }, */
]
